@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Oswald:wght@500&display=swap');

body {
  background-image: url("images/background.svg");
  background-size: 400vh;
  background-color: grey;
  background-blend-mode: multiply;
  background-attachment: fixed; /* Make the background image sticky */
  font-family: 'Poppins', sans-serif; /* Updated font family */
  letter-spacing: -1px; 
}

h1 {
  text-align: center;
  color: yellow;
}
