/* Navbar.css */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.5rem;
}

.logo img {
  margin-top: 1rem;
  height: 100px;
  margin-left: 10rem;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger-line {
  width: 25px;
  height: 3px;
  background-color: rgb(255, 255, 0);
  margin: 4px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 8rem;
}

.nav-links img {
  display: none;
}

.nav-links li {
  margin-right: 32px;
}

.nav-links li a {
  color: rgb(0, 0, 0);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}

/* Add the underline animation */
.nav-links li a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: rgb(255, 255, 0);
  transition: width 0.3s ease;
}

/* Hover effect for the links */
.nav-links li a:hover {
  color: rgb(255, 255, 0);
}

/* Hover effect for the underline animation */
.nav-links li a:hover::before {
  width: 100%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .navbar {
    padding-top: 1.5rem;
  }
  .logo {
    display: block;
    z-index: 2;
    margin: auto;
  }
  .logo img {
    height: 100px;
    margin-left: 2.5rem;
  }
  .burger {
    display: block;
    z-index: 3;
    margin-right: 1rem;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: -20px;
    right: 0;
    left: -150%;
    z-index: 1;
    height: 100%;
    width: 91%;
    transition: left 0.3s ease;
  }
  .nav-links li a {
    color: black;
    font-weight: 600;
    background-color: rgb(255, 255, 99);
    border-radius: 33px;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }

  .nav-links.open {
    position: fixed;
    overflow-y: hidden;
    height: 200vh;
    overflow: hidden !important;
    left: 0;
  }


  .nav-links li:first-child {
    margin-top: 15rem;
  }
  .nav-links li {
    display: flex;
    padding: 1rem;
  }
  /* Add the underline animation */
  .nav-links li a::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgb(255, 255, 0);
    transition: width 0.3s ease;
  }

  /* Hover effect for the links */
  .nav-links li a:hover {
    color: rgb(0, 0, 0);
  }

  /* Hover effect for the underline animation */
  .nav-links li a:hover::before {
    width: 0%;
  }
}
