body {
  margin: 0;
  letter-spacing: 1px !important;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: yellow;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(206, 206, 0);
  cursor: pointer;
}