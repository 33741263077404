.back-to-top-button {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    height: 50px; 
    width: 50px; 
    background-color: rgba(255, 255, 0, 0.8);
    border: 1px solid white;
    border-radius: 50%; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; 
    font-weight: 600;
}

.back-to-top-button img {
    width: 80%;
    height: auto;
}

.back-to-top-button:hover {
    background-color: rgba(255, 255, 0, 1); 
}

@media (max-width: 767px) {
    .back-to-top-button {
        bottom: 10px;
        right: 10px; 
        height: 40px; 
        width: 40px; 
        font-size: 14px; 
    }

    .back-to-top-button img {
        width: 70%; 
    }
}
