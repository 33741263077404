/* Impressum.css */

.impressum-container {
    width: 100%;
    text-align: center;
    margin: auto;
  }
  
  .impressum-card {
    margin: auto;
    width: 90%;
    background-color: #ffffff; /* White background for the cards */
    border-radius: 10px; /* Add rounded corners to the cards */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow to the cards */
    margin-bottom: 20px;
    padding: 20px;
    text-align: left;
  }
  
  .impressum-card h2 {
    color: #000; /* Black text for card titles */
  }
  
  .impressum-link {
    color: #007bff; /* Blue color for links */
    text-decoration: none;
  }
  
  .impressum-link:hover {
    text-decoration: underline; /* Underline links on hover */
  }
  #uebertitel{
   text-align: left; 
  }