.copyright-bar {
  background-color: #333; /* Background color for the bar */
  color: #fff; /* Text color */
  text-align: center; /* Center-align text */
  padding: 10px 0; /* Add some padding for spacing */
  font-size: 14px; /* Adjust the font size as needed */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: rgb(0, 0, 0);
}

h2{
  text-decoration: underline;
}

.layout {
  display: flex;
  width: 100%;
}
.lefty {
  color: rgb(0, 0, 0);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
}

.links {
  display: flex;
  flex-direction: column;
}

.middy {
  color: rgb(0, 0, 0);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
}

.offen {
  display: flex;
  flex-direction: column;
}
.offen h4 {
  margin: 0;
}
.offen p {
  text-decoration: underline;
}

.righty {
  color: rgb(0, 0, 0);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: #ffffff;
}
.links-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .layout {
    width: 100%;
    flex-direction: column;
  }
  .lefty,
  .middy,
  .righty {
    width: 100%;
  }
  .offen {
    text-align: center;
  }
  .links-container {
    text-align: center;
  }
  .links {
    flex-direction: column;
  }
  .links a {
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-bottom: 10px;
  }
  .tele{
    text-align: center;
  }
}
