.maps{
    display: flex;
    align-items: center;
    justify-content: center;
}
.kontakt{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.507);
    padding: 1rem 0 1rem 0;
}

.div1, .email{
    font-size: 1.2em;
    height: auto;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: yellow;
    border-radius: 5px;
    text-align: center;
}
.oeffnungszeiten{
    line-height: 0.8;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 80%;
    margin: 1rem;
}

@media (max-width: 768px) {
    .kontakt{
        width: 100%;
        flex-direction: column;
    }
    .div1, .email{
        font-size: 1em !important;
    }
    .div1, .email{
        padding: auto;
        height: 40vh;
        width: 90%;
    }
    .email{
        margin-top: 1rem;
    }
}

.btn123 {
    padding: 15px 25px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    border: unset;
    border-radius: 15px;
    color: #64644e;
    z-index: 0;
    background: #e8e8e8;
    position: relative;
    font-weight: 1000;
    font-size: 17px;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    transition: all 250ms;
    overflow: hidden;
   }
   
   .btn123::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #000000;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    transition: all 250ms
   }
   
   .btn123:hover {
    color: #fbff00;
   }
   
   .btn123:hover::before {
    width: 100%;
   }