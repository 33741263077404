
.modal-overlay {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.582);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 80%;
    background-color: black;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal-image {
    max-width: 100vh;
    max-height: 80vh;
  }