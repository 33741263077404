.cover {
    display: flex;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left {
    flex-direction: column;
    background-color: rgba(255, 255, 255);
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.left h2 {
    font-size: 2em;
    text-align: center;
}

.left p {
    font-size: 1.25em;
    width: 75%;
    text-align: center;
}

.right {
    background-color: rgba(255, 255, 255);
    display: flex;
    width: 50%;
}

#logo {
    width: 80vh;
    height: 48vh;
    margin: 2rem;
}

.history {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.753);
    color: white;
}
#meister{
    margin-top: -1.5rem;
}

.bild{
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.liste{
    margin:2rem;
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.liste li{
    font-size: 1.25em;
}
.history p {
    width: 75%;
    text-align: center;
}

#kostja{
    width: 40vh;
    height: 100%;
}

.auto-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#auto{
    width: 80%;
}

@media (max-width: 786px) {
    .auto-body{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #auto{
        width: 100%;
    }
    .cover {
        flex-direction: column;
        text-align: center;
    }
    
    #logo {
        width: 40vh;
        height: 24vh;
        margin: 2rem;
    }
    .left, .right{
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items:center ;
    }
    .left h2{
        font-size: 1.5em;
        margin-top: 2rem;
    }
    .left p{
        width: 75%;
        margin-top: 2rem;
        font-size: 1em;
    }
    .history{
        flex-direction: column;
    }
    .bild, .liste{
        margin: 0;
        padding-bottom: 1rem;
        width: 100%;
        text-align: center;
    }
    .liste li{
        font-size: 1em;
    }
}

.card {
    width: 100%;
    background-color: yellow;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    text-align: center;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
  }

  .work-list {
    list-style-type: none;
    padding: 0;
  }

  .work-item {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }

  .work-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: black;
  }

  .work-title {
    color: black;
  }