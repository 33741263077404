body {
  overflow-x: hidden !important;
}
.cover-container {
  display: flex;
}
#title {
  font-size: 2.5em;
  border-radius: 33px;
}
.cover1,
.cover2 {
  animation: fadeInUp 1s ease-in-out;
}
#jklo {
  color: black;
}
a {
  text-decoration: none;
}
#asdf {
  text-decoration: none;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.cover1 {
  width: 50%;
  background-image: url("../../images/logo2.png");
  background-position: center;
  background-size: contain;
  height: 35vh;
  background-repeat: no-repeat;
}

.cover2 {
  width: 50%;
  background-image: url("../../images/logo2.png");
  background-position: center;
  background-size: contain;
  height: 35vh;
  background-repeat: no-repeat;
}
.text {
  margin: auto;
}
.splide__slide img {
  width: 100%;
  height: auto;
}
#blickfang{
  width: 40%;
}
.splide__slide{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.cover-text {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 100;
}
.cover-text span {
  color: yellow;
  font-weight: 500;
}
.catchphrase {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;
  background-color: rgb(0, 0, 0);
  color: white;
}
.catchphrase p {
  padding: 10px;
  width: 50%;
  font-size: 2em;
  font-weight: 600;
  text-align: start;
}
.catchphrase p span {
  color: #ddff00;
  padding-bottom: 10px;
}

.catchphrase a {
  text-decoration: none;
}

.service-btn {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: 1s;
  padding: 10px 20px;
  border-radius: 100px;
  background: #cfef00;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  width: auto;
}

.service-btn:hover {
  background: yellow !important;
}
.service-btn > svg {
  width: 50px;
  margin-left: 10px;
  transition: transform 0.6s ease-in-out;
}

.service-btn:hover svg {
  transform: translateX(25px);
}

.showcase {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.showcase img {
  width: 100%;
  border-radius: 4px;
}

.showcase h2 {
  color: yellow;
}

.showcase a p {
  color: white;
  width: 90%;
  margin: auto;
}

.smarthome,
.beleuchtung,
.energie,
.umbau {
  font-size: 1em;
  border: 2px rgb(255, 238, 0) solid;
  border-radius: 1rem;
  background: black;
  margin: 1rem;
  width: 25%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smarthome a,
.beleuchtung a,
.energie a,
.umbau a {
  height: max-content;
}
.smarthome:hover,
.beleuchtung:hover,
.energie:hover,
.umbau:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition-duration: 0.3s;
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
}

.text-row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.img-row {
  max-width: 100%;
  white-space: nowrap;
}
.splide__slide:hover{
  cursor: pointer;
}
@media (max-width: 768px) {
  .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
    padding: 3rem;
  }
  #blickfang{
    width: 100%;
  }
  .splide__list,
  .splide__track {
    display: block;
    height: auto !important; 
    align-content: center;
  }
  
  .splide__slide img {
    height: auto;
    width: 200%;
  }
  
  .row1 {
    flex-direction: column;
  }
  .row1 div {
    text-align: center;
    width: 100%;
  }
  .partners {
    padding: 0;
    flex-direction: column;
  }
  #homematic {
    height: 6vh;
  }
  #rarchitect {
    height: 20vh;
  }
  #sterne {
    height: 20vh;
  }
  .partners img:first-child {
    margin-top: 2rem;
  }
  .showcase {
    flex-direction: column;
  }
  .smarthome,
  .beleuchtung,
  .energie,
  .umbau {
    width: 80%;
    height: auto;
  }
  #title {
    font-size: 1.5em;
  }
  .catchphrase p {
    width: 100%;
    font-size: 1.25em;
    margin: 0;
    text-align: center;
    margin-bottom: -3rem;
  }
  .service-btn {
    margin: auto;
  }
}
