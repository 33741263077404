.gallerie-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 20px;
}

.gallery-section {
  text-align: center;
}

.gallerie-image {
  padding-bottom: 1.5rem;
  max-width: 75%;
  height: auto;
  display: block;
  margin: auto;
}

@media (max-width: 768px) {
  .gallerie-container {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 0;
  }
  .gallery-section {
    padding: 0;
  }
  .gallerie-image{
    max-width: 100%;
    margin: 0;
  }
}
#testest{
  cursor: pointer;
}
