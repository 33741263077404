.description-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.589);
}
.description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30vh;
  background-color: rgba(255, 255, 255, 0.75);
}
.description p {
  text-align: center;
  font-size: 1.25em;
  font-weight: 500;
  width: 80%;
}

.mz-container {
  display: flex;
  width: 100%;
}

.mz-1,
.mz-2,
.mz-3,
.mz-4,
.mz-5 {
  font-size: 1.25em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20%;
  background-color: rgb(255, 255, 255);
  font-weight: 600;
  color: black;
}
.mz-1 img,
.mz-2 img,
.mz-3 img,
.mz-4 img,
.mz-5 img {
  margin-top: 2rem;
  padding: 20px;
  height: 20vh;
}

.picContainer, .textContainer{
  text-align: center;
  width: 195px;
}
.textContainer{
  display: flex;
  justify-content: center;
  align-items: center ;
}


.buttons {
  display: flex;
  justify-content: center;
}

.buttons button {
  font-size: 1em;
  width: 300px;
  height: 60px;
  font-weight: 600;
  color: white;
  padding: 10px 20px;
  background-color: #000000;
  cursor: pointer;
}

.buttons button.active {
  color: yellow;
  background-color: #000000;
  border: 3px solid yellow;
}

.hide-content {
  display: none;
}

.show-content {
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #000000;
}


.over-container {
  display: flex;
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.img-container {
  margin-top: 3rem;
  margin-right: 3rem;
  flex: 1;
  padding: 20px;
  text-align: center;
}

.img-container img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.list {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8rem;
  text-align: left;
}

.list h2 {
  text-align: left !important;
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

.service-list {
  list-style: none;
  padding: 0;
}

.service-list li {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
}

@media (max-width: 768px) {
  .textContainer{
    display: block;
    text-align: left;
  }
  .over-container {
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .list {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    text-align: center;
  }.list h2 {
    text-align: center !important;
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
  }
  .img-container{
    margin-right: 0 !important;
    height: auto;
    margin: auto;
  }
  .img-container img{
    margin-top: -3rem;
  }
  #gif{
    margin-top: auto;
  }
  .buttons {
    width: 100%;
    flex-direction: column;
  }
  
  .buttons button {
    font-size: 1em;
    width: 100%;
  }
  
  .buttons button.active {
    color: white;
    background-color: #000000;
    border: 2px solid yellow;
  }
  
  .mz-container {
    flex-direction: column;
  }
  .mz-1,
  .mz-2,
  .mz-3,
  .mz-4,
  .mz-5 {
    flex-direction: row;
    width: 100%;
  }
  .mz-1 img,
  .mz-2 img,
  .mz-3 img,
  .mz-4 img,
  .mz-5 img {
    background-color: white;
    padding: 20px;
    height: 10vh;
  }
  .description p {
    font-size: 0.85em;
  }
  .description {
    text-align: center;
    height: 40vh;
  }
}
@media (max-width: 1024px) and (orientation: landscape) {
  .description p{
    font-size: 1em;
  }
}
